.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  overflow-x: hidden;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
  overflow-x: hidden;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 300px;
}
